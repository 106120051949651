<template>
  <div class="bg-white relative flex-1">
    <!-- top header start -->
    <div class="my-6 max-w-1/6 mx-auto px-4 sm:px-6 lg:px-8">
      <div class="md:flex md:items-center md:justify-between">
        <div class="flex min-w-0">
          <svg-vue class="content-title-icon" icon="font-awesome.poll-h-solid"></svg-vue>
          <h1 class="content-title">
            {{ $t('Sale orders') }}
          </h1>
        </div>
        <div class="mt-4 flex md:mt-0">
          <div class="mx-1">
            <button @click="openFiltersSidebar" class="inline-flex justify-center w-full btn btn-app" id="filter-button" type="button">
              <svg-vue class="self-center mr-2 h-4 w-4" icon="font-awesome.filter-regular"></svg-vue>
              {{ $t('Filters') }}
              <svg-vue class="self-center mx-1.5 -mx-1 h-3 w-3" icon="font-awesome.chevron-right-solid"></svg-vue>
            </button>
            <!-- side filters -->
            <div class="fixed inset-0 overflow-hidden z-30" v-show="filtersSidebar">
              <div class="absolute inset-0 overflow-hidden">
                <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex" v-on-clickaway="closeFiltersSidebar">
                  <transition
                    duration="500"
                    enter-active-class="transform transition ease-in-out duration-500"
                    enter-class="translate-x-full"
                    enter-to-class="translate-x-0"
                    leave-active-class="transform transition ease-in-out duration-500"
                    leave-class="translate-x-0"
                    leave-to-class="translate-x-full"
                  >
                    <div class="w-screen max-w-xs" v-show="filtersSidebar">
                      <div class="h-full flex flex-col space-y-4 bg-white shadow-xl">
                        <header class="px-4 pt-4 sm:px-6">
                          <div class="flex items-start justify-between space-x-3">
                            <h2 class="text-lg leading-7 font-medium text-gray-900">
                              {{ $t('Filters') }}
                            </h2>
                            <div class="h-7 flex items-center">
                              <button
                                @click="closeFiltersSidebar"
                                aria-label="Close panel"
                                class="text-gray-400 hover:text-gray-900 transition ease-in-out duration-150"
                                type="button"
                              >
                                <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </header>
                        <div class="relative flex-1 px-4 sm:px-6 h-full border-t overflow-y-scroll">
                          <div class="absolute inset-0 px-4 sm:px-6">
                            <div class="grid grid-cols-3 py-3">
                              <div class="col-span-3 my-2">
                                <label class="form-label text-sm" for="search">{{ $t('Search') }}</label>
                                <div class="relative flex-grow focus-within:z-10">
                                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg-vue class="h-4 w-4 text-gray-400" icon="font-awesome.search-regular"></svg-vue>
                                  </div>
                                  <input
                                    :placeholder="$t('Search')"
                                    @change="getSaleOrderList"
                                    aria-label="Search"
                                    class="form-input block w-full pl-10 text-sm transition ease-in-out duration-150"
                                    id="search"
                                    v-model.lazy="filters.search"
                                  />
                                </div>
                              </div>
                              <div class="col-span-3 my-2">
                                <label class="form-label text-sm" for="order_type">{{ $t('By Order Type') }}</label>
                                <select @change="getSaleOrderList" aria-label="Sort by" class="form-input" id="order_type" v-model="filters.order_type">
                                  <option :value="null">{{ $t('By Order Type') }}</option>
                                  <option v-for="(ortype, index) in orderTypes" :key="index" :value="ortype.key">{{ ortype.title }}</option>
                                </select>
                              </div>
                              <div v-if="filters.order_type === 'dining'" class="col-span-3 my-2">
                                <label class="form-label text-sm" for="table">{{ $t('By Serice table') }}</label>
                                <select @change="getSaleOrderList" aria-label="Sort by" class="form-input" id="table" v-model="filters.table">
                                  <option :value="null">{{ $t('By table') }}</option>
                                  <template v-for="(table, index) in tableList">
                                    <option :key="index" :value="table.id">
                                      {{ table.title }}
                                    </option>
                                  </template>
                                </select>
                              </div>
                              <div class="col-span-3 my-2">
                                <label class="form-label text-sm" for="taker">{{ $t('By order taker') }}</label>
                                <select @change="getSaleOrderList" class="form-input" id="taker" v-model="filters.taker">
                                  <option :value="null">{{ $t('By taker') }}</option>
                                  <template v-for="(taker, index) in takerList">
                                    <option :key="index" :value="taker.id">
                                      {{ taker.name }}
                                    </option>
                                  </template>
                                </select>
                              </div>
                              <div class="col-span-3 my-2">
                                <label class="form-label text-sm" for="chef">{{ $t('By Chef') }}</label>
                                <select @change="getSaleOrderList" aria-label="Sort by" class="form-input" id="chef" v-model="filters.chef">
                                  <option :value="null">{{ $t('By Chef') }}</option>
                                  <template v-for="(chef, index) in chefList">
                                    <option :key="index" :value="chef.id">
                                      {{ chef.name }}
                                    </option>
                                  </template>
                                </select>
                              </div>
                              <div class="col-span-3 my-2">
                                <label class="form-label text-sm" for="biller">{{ $t('By Biller') }}</label>
                                <select @change="getSaleOrderList" aria-label="Sort by" class="form-input" id="biller" v-model="filters.biller">
                                  <option :value="null">{{ $t('By biller') }}</option>
                                  <template v-for="(biller, index) in billerList">
                                    <option :key="index" :value="biller.id">
                                      {{ biller.name }}
                                    </option>
                                  </template>
                                </select>
                              </div>
                              <div class="col-span-3 my-2">
                                <label class="form-label text-sm" for="label">{{ $t('Sort') }}</label>
                                <div class="relative inline-flex w-full">
                                  <button @click="changeSort" class="form-input rounded-none rounded-l-md flex" type="button">
                                    <svg-vue class="h-5 w-5 text-gray-400" icon="font-awesome.sort-amount-down-alt-regular" v-show="sort.order === 'asc'"></svg-vue>
                                    <svg-vue class="h-5 w-5 text-gray-400" icon="font-awesome.sort-amount-up-alt-regular" v-show="sort.order === 'desc'"></svg-vue>
                                    <span class="ml-2">{{ $t('Sort') }}</span>
                                  </button>
                                  <select
                                    @change="changeSort"
                                    aria-label="Sort by"
                                    class="form-input rounded-none w-full rounded-r-md"
                                    id="sort.column"
                                    v-model="sort.column"
                                  >
                                    <option value="tracking">
                                      {{ $t('Tracking') }}
                                    </option>
                                    <option value="id">
                                      {{ $t('Order Number') }}
                                    </option>
                                    <option value="created_at">
                                      {{ $t('Created at') }}
                                    </option>
                                    <option value="updated_at">
                                      {{ $t('Updated at') }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-span-3 my-2">
                                <label class="form-label text-sm" for="label">{{ $t('Items per page') }}</label>
                                <input-select
                                  :options="[
                                    { id: 5, name: 5 },
                                    { id: 10, name: 10 },
                                    { id: 25, name: 25 },
                                    { id: 50, name: 50 },
                                  ]"
                                  @change="getSaleOrderList"
                                  id="per_page"
                                  class="form-input"
                                  option-label="name"
                                  required
                                  v-model="perPage"
                                ></input-select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </section>
              </div>
            </div>
            <!-- end -->
          </div>
          <button @click="getSaleOrderList" class="btn btn-app mx-1" type="button">
            <svg-vue class="w-4 h-4" icon="font-awesome.sync-regular"></svg-vue>
          </button>
          <router-link to="/admin" class="btn btn-app mx-1">
            {{ $t('Back') }}
          </router-link>
        </div>
      </div>
    </div>
    <!-- top header ends -->

    <loading :status="loading"></loading>
    <!-- table start -->
    <div class="sales-list">
      <!-- table  has rows-->
      <template v-if="saleList.length > 0">
        <div class="sm:hidden">
          <ul class="border-b border-gray-200 divide-y divide-gray-200">
            <template v-for="(sale, index) in saleList">
              <li :key="index">
                <router-link :to="'/admin/sales/' + sale.uuid + '/view'" class="flex items-center justify-between px-4 py-4 hover:bg-gray-100 sm:px-6">
                  <div class="flex items-center truncate space-x-3">
                    <div class="whitespace-no-wrap">
                      <div class="text-sm leading-5 w-full truncate">
                        <span class="bg-gray-200 px-1 mr-1">ID # {{ sale.id }} </span>
                        <span v-if="sale.customer" class="bg-gray-200 px-1 mr-1">{{ $t('Customer') }} : {{ sale.customer.name }}</span>
                      </div>
                      <div class="text-sm leading-5 w-full truncate">
                        <span class="bg-yellow-200 px-1 mr-1"> {{ $t('Cost') }} : {{ sale.cart_total_cost | currency }} </span>
                        <span class="bg-yellow-200 px-1 mr-1"> {{ $t('Price') }} : {{ sale.cart_total_price | currency }} </span>
                      </div>
                      <div class="text-sm leading-5 w-full truncate">
                        <span class="bg-yellow-200 px-1 mr-1"> {{ $t('Discount') }} : {{ sale.discount_amount | currency }} </span>
                        <span class="bg-yellow-200 px-1 mr-1">{{ $t('Tax') }} : {{ sale.tax_amount | currency }}</span>
                      </div>
                      {{ $t('Last update') }} : {{ sale.updated_at }}
                    </div>
                  </div>
                  <svg-vue
                    class="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150"
                    icon="font-awesome.angle-right-regular"
                  ></svg-vue>
                </router-link>
              </li>
            </template>
          </ul>
        </div>
        <div class="hidden sm:block">
          <div class="align-middle inline-block min-w-full border-b border-gray-200">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr class="bg-cyan-900 text-orange-400">
                  <th class="hidden lg:table-cell p-2 text-left text-xs leading-4 font-semibold uppercase tracking-wider text-center">
                    {{ $t('Tracking') }}
                  </th>
                  <th class="p-2 text-left text-xs leading-4 font-semibold uppercase tracking-wider whitespace-no-wrap overflow-x-auto">
                    {{ $t('Information') }}
                  </th>
                  <th class="p-2 text-left text-xs leading-4 uppercase">{{ $t('Table') }}/{{ $t('Type') }}</th>
                  <th class="p-2 text-left text-xs leading-4 uppercase">{{ $t('Status') }}</th>
                  <th class="p-2 text-left text-xs leading-4 uppercase">
                    {{ $t('Booked at') }}
                  </th>
                  <th class="p-2 text-left text-xs leading-4 uppercase">
                    {{ $t('Prepared at') }}
                  </th>
                  <th class="p-2 text-left text-xs leading-4 uppercase">
                    {{ $t('Completed at') }}
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <template v-for="(sale, index) in saleList">
                  <router-link :to="'/admin/sales/' + sale.uuid + '/view'" class="cursor-pointer hover:bg-gray-100" tag="tr">
                    <td class="hidden lg:table-cell px-3 py-1 whitespace-no-wrap leading-5">
                      <barcode :value="sale.tracking" font-size="10" width="1" height="20"></barcode>
                    </td>
                    <td class="py-1 max-w-0 w-full md:w-1/2 whitespace-no-wrap">
                      <div class="text-sm leading-5 w-full truncate">
                        <span class="bg-gray-200 px-1 mr-1">ID # {{ sale.id }} </span>
                        <span v-if="sale.customer" class="bg-gray-200 px-1 mr-1">{{ $t('Customer') }} : {{ sale.customer.name }}</span>
                      </div>
                      <div class="text-sm leading-5 w-full truncate">
                        <span class="bg-yellow-200 px-1 mr-1"> {{ $t('Cost') }} : {{ sale.cart_total_cost | currency }} </span>
                        <span class="bg-yellow-200 px-1 mr-1"> {{ $t('Price') }} : {{ sale.cart_total_price | currency }} </span>
                        <span class="bg-yellow-200 px-1 mr-1"> {{ $t('Discount') }} : {{ sale.discount_amount ?? 0 | currency }} </span>
                        <span class="bg-yellow-200 px-1 mr-1">{{ $t('Tax') }} : {{ sale.tax_amount | currency }}</span>
                      </div>
                    </td>
                    <td class="px-2 py-1 whitespace-no-wrap leading-5 text-xs text-center">
                      <span class="px-2 bg-yellow-200 py-1 rounded" v-if="sale.table"> {{ sale.table.title }} </span>
                      {{ decodeOrderType(sale.order_type) }}
                    </td>
                    <td class="px-2 py-1 whitespace-no-wrap leading-5 text-xs text-center">{{ sale.progress ?? 0 }}%</td>
                    <td class="px-2 py-1 whitespace-no-wrap leading-5 text-xs">
                      {{ sale.took_at }}
                    </td>
                    <td class="px-2 py-1 whitespace-no-wrap leading-5 text-xs">
                      {{ sale.prepared_at }}
                    </td>
                    <td class="px-2 py-1 whitespace-no-wrap leading-5 text-xs">
                      {{ sale.completed_at }}
                    </td>
                  </router-link>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <!-- end -->
      <!-- no rows -->
      <template v-else>
        <no-content :search="anyFilter" />
      </template>
      <!-- end -->
    </div>
    <!-- table ends -->
    <!-- bottom nav start -->
    <nav class="bg-white absolute bottom-0 left-0 w-full px-4 py-2 flex items-center justify-between border-t border-gray-500 sm:px-6">
      <div class="hidden sm:block">
        <p class="text-sm leading-5 text-gray-700">
          {{ $t('Showing') }}
          <span class="font-medium">{{ pagination.perPage * pagination.currentPage - pagination.perPage + 1 }}</span>
          {{ $t('to') }}
          <span class="font-medium">{{
            pagination.perPage * pagination.currentPage <= pagination.total ? pagination.perPage * pagination.currentPage : pagination.total
          }}</span>
          {{ $t('of') }}
          <span class="font-medium">{{ pagination.total }}</span>
          {{ $t('results') }}
        </p>
      </div>
      <div class="flex-1 flex justify-between sm:justify-end">
        <button
          :class="pagination.currentPage <= 1 ? 'opacity-50 cursor-not-allowed' : ''"
          :disabled="pagination.currentPage <= 1"
          @click="changePage(pagination.currentPage - 1)"
          class="pagination-link"
          type="button"
        >
          {{ $t('Previous') }}
        </button>
        <button
          :class="pagination.currentPage >= pagination.totalPages ? 'opacity-50 cursor-not-allowed' : ''"
          :disabled="pagination.currentPage >= pagination.totalPages"
          @click="changePage(pagination.currentPage + 1)"
          class="ml-3 pagination-link"
          type="button"
        >
          {{ $t('Next') }}
        </button>
      </div>
    </nav>
    <!-- bottom nav ends -->
  </div>
</template>
<script>
import { pickBy, identity } from 'lodash';
import { mixin as clickaway } from 'vue-clickaway';
import VueBarcode from 'vue-barcode';
import { mapGetters } from 'vuex';

export default {
  name: 'list',
  components: {
    barcode: VueBarcode,
  },
  metaInfo() {
    return {
      title: this.$i18n.t('Sale orders'),
    };
  },
  mixins: [clickaway],
  data() {
    return {
      loading: true,
      filtersSidebar: false,
      tableList: [],
      takerList: [],
      chefList: [],
      billerList: [],
      filters: {
        search: null,
        chef: null,
        biller: null,
        taker: null,
        order_type: null,
        table: null,
      },

      sort: {
        order: 'desc',
        column: 'updated_at',
      },
      page: 1,
      perPage: 10,
      pagination: {
        currentPage: 0,
        perPage: 0,
        total: 0,
        totalPages: 0,
      },

      saleList: [],
    };
  },
  computed: {
    ...mapGetters({
      orderTypes: 'app/getOrderTypes',
    }),
    anyFilter() {
      return pickBy(this.filters, identity);
    },
  },
  mounted() {
    this.getFilters();
    this.getSaleOrderList();
  },
  methods: {
    decodeOrderType(orderkey) {
      let data = {};
      this.orderTypes.forEach((item) => {
        if (item.key === orderkey) {
          data = item;
        }
      });
      return data.title;
    },
    openFiltersSidebar() {
      setTimeout(() => {
        this.filtersSidebar = true;
      }, 100);
    },
    closeFiltersSidebar() {
      this.filtersSidebar = false;
    },

    changePage(page) {
      if (page > 0 && page <= this.pagination.totalPages && page !== this.page) {
        this.page = page;
        this.getSaleOrderList();
      }
    },
    changeSort() {
      if (this.sort.order === 'asc') {
        this.sort.order = 'desc';
      } else if (this.sort.order === 'desc') {
        this.sort.order = 'asc';
      }
      this.getSaleOrderList();
    },
    getFilters() {
      axios.get(route('sale.filters')).then((response) => {
        this.tableList = response.data.tables;
        this.takerList = response.data.takers;
        this.chefList = response.data.chefs;
        this.billerList = response.data.billers;
      });
    },
    getSaleOrderList() {
      this.loading = true;
      axios
        .get(route('sales.index'), {
          params: {
            page: this.page,
            sort: this.sort,
            perPage: this.perPage,
            ...this.filters,
          },
        })
        .then((response) => {
          this.saleList = response.data.items;
          this.pagination = response.data.pagination;
          if (this.pagination.totalPages < this.pagination.currentPage) {
            this.page = this.pagination.totalPages;
            this.getSaleOrderList();
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.sales-list {
  @apply bg-white w-full z-0 overflow-y-auto;
  height: calc(100vh - 270px);
}

@media (min-width: theme('screens.md')) {
  .sales-list {
    height: calc(100vh - 170px);
  }
}

.filters-options {
  @apply bg-white p-3 overflow-y-auto;
  height: calc(100vh - 270px);
  max-height: 360px;
}

@media (min-width: theme('screens.md')) {
  .filters-options {
    height: calc(100vh - 140px);
  }
}
</style>
