<template>
  <div class="pt-5">
    <menu-item
      :label="$t('Dashboard')"
      v-show="visibility('dashboard_access')"
      :mobile="mobile"
      icon="font-awesome.palette-solid"
      to="/admin/dashboard"
    ></menu-item>

    <menu-item
      v-if="visibility('manage_sales')"
      :label="$t('Sales')"
      :mobile="mobile"
      icon="font-awesome.poll-h-solid"
      to="/admin/sales"
    ></menu-item>

    <div
      class="my-2 mx-4 text-orange-500 text-xs"
      v-if="groupVisibility(portalGates)"
    >
      {{ $t('Portals') }}
    </div>

    <menu-item
      :label="$t('POS')"
      v-if="visibility('pos_portal')"
      :mobile="mobile"
      icon="font-awesome.store-solid"
      to="/pos"
    ></menu-item>

    <menu-item
      :label="$t('kitchen')"
      v-if="visibility('kitchen_portal')"
      :mobile="mobile"
      icon="font-awesome.utensils-solid"
      to="/kitchen-display"
    ></menu-item>

    <div
      class="my-2 mx-4 text-orange-500 text-xs"
      v-if="groupVisibility(foodGates)"
    >
      {{ $t('Foods') }}
    </div>

    <menu-item
      v-if="visibility('manage_food_categories')"
      :label="$t('Categories')"
      :mobile="mobile"
      icon="font-awesome.sitemap-solid"
      to="/admin/categories"
    ></menu-item>
    <menu-item
      v-if="visibility('manage_food_items')"
      :label="$t('Items')"
      :mobile="mobile"
      icon="font-awesome.th-list-solid"
      to="/admin/products"
    ></menu-item>
    <menu-item
      v-if="visibility('manage_modifiers')"
      :label="$t('Modifiers')"
      :mobile="mobile"
      icon="font-awesome.stream-solid"
      to="/admin/modifiers"
    ></menu-item>

    <menu-item
      v-if="visibility('manage_ingredients')"
      :label="$t('Ingredients')"
      :mobile="mobile"
      icon="font-awesome.boxes-solid"
      to="/admin/ingredients"
    ></menu-item>

    <div
      class="my-2 mx-4 text-orange-500 text-xs"
      v-if="groupVisibility(expenseGates)"
    >
      {{ $t('Expenses') }}
    </div>
    <menu-item
      v-if="visibility('manage_expense_types')"
      :label="$t('Expense Types')"
      :mobile="mobile"
      icon="font-awesome.sitemap-solid"
      to="/admin/expense-types"
    ></menu-item>

    <menu-item
      v-if="visibility('manage_expenses')"
      :label="$t('Expenses')"
      :mobile="mobile"
      icon="font-awesome.file-invoice-solid"
      to="/admin/expenses"
    ></menu-item>

    <div
      class="my-2 mx-4 text-orange-500 text-xs"
      v-if="groupVisibility(peopleGates)"
    >
      {{ $t('People') }}
    </div>
    <menu-item
      v-if="visibility('manage_users')"
      :label="$t('Users')"
      :mobile="mobile"
      icon="font-awesome.users-solid"
      to="/admin/users"
    ></menu-item>

    <menu-item
      v-show="visibility('manage_user_roles')"
      :label="$t('User roles')"
      :mobile="mobile"
      icon="font-awesome.user-lock-solid"
      to="/admin/user-roles"
    ></menu-item>
    <menu-item
      v-if="visibility('manage_customers')"
      :label="$t('Customers')"
      :mobile="mobile"
      icon="font-awesome.users-class-regular"
      to="/admin/customers"
    ></menu-item>

    <div
      v-if="groupVisibility(reportGates)"
      class="my-2 mx-4 text-orange-500 text-xs"
    >
      {{ $t('Reports') }}
    </div>

    <menu-item
      v-show="visibility('overall_report')"
      :label="$t('Overall Report')"
      :mobile="mobile"
      icon="font-awesome.file-invoice-solid"
      to="/admin/overall-report"
    ></menu-item>

    <menu-item
      v-show="visibility('tax_report')"
      :label="$t('Tax Report')"
      :mobile="mobile"
      icon="font-awesome.file-invoice-solid"
      to="/admin/tax-report"
    ></menu-item>

    <menu-item
      v-show="visibility('expense_report')"
      :label="$t('Expense Report')"
      :mobile="mobile"
      icon="font-awesome.file-invoice-solid"
      to="/admin/expense-report"
    ></menu-item>
    <menu-item
      v-show="visibility('stock_alerts')"
      :label="$t('Stock alert')"
      :mobile="mobile"
      icon="font-awesome.file-invoice-solid"
      to="/admin/stock-alerts"
    ></menu-item>

    <div
      class="my-2 mx-4 text-orange-500 text-xs"
      v-if="groupVisibility(advanceGates) || groupVisibility(settingGates)"
    >
      {{ $t('Advance') }}
    </div>
    <menu-item
      v-if="visibility('import_exports')"
      :label="$t('Imports exports')"
      :mobile="mobile"
      icon="font-awesome.recycle-solid"
      to="/admin/imports-exports"
    ></menu-item>
    <menu-item
      v-if="visibility('manage_service_tables')"
      :label="$t('Service Tables')"
      :mobile="mobile"
      icon="font-awesome.star-regular"
      to="/admin/service-tables"
    ></menu-item>
    <menu-item
      v-if="visibility('manage_payment_methods')"
      :label="$t('Payment methods')"
      :mobile="mobile"
      icon="font-awesome.dollar-sign-solid"
      to="/admin/payment-methods"
    ></menu-item>
    <menu-item
      v-if="visibility('database_backup')"
      :label="$t('Backup')"
      :mobile="mobile"
      icon="font-awesome.database-solid"
      to="/admin/backup"
    ></menu-item>

    <menu-item
      v-if="groupVisibility(settingGates)"
      :label="$t('Settings')"
      :mobile="mobile"
      icon="font-awesome.cogs-solid"
      to="/admin/settings"
    ></menu-item>
    <menu-item
      v-if="visibility('manage_languages')"
      :label="$t('Languages')"
      :mobile="mobile"
      icon="font-awesome.globe-solid"
      to="/admin/Languages"
    ></menu-item>
  </div>
</template>
<script>
import { includes, forEach } from 'lodash';
import MenuItem from '@/Components/layout/admin/menu/menu-item';
export default {
  name: 'menu-list',
  components: { MenuItem },
  props: {
    mobile: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      foodGates: [
        'manage_food_categories',
        'manage_food_items',
        'manage_modifiers',
        'manage_ingredients',
      ],
      portalGates: ['pos_portal', 'kitchen_portal'],
      expenseGates: ['manage_expense_types', 'manage_expenses'],
      peopleGates: ['manage_users', 'manage_user_roles', 'manage_customers'],
      reportGates: [
        'overall_report',
        'tax_report',
        'expense_report',
        'stock_alerts',
      ],
      advanceGates: [
        'import_exports',
        'manage_service_tables',
        'manage_payment_methods',
        'database_backup',
        'manage_languages',
      ],
      settingGates: [
        'general_configuration',
        'appearance_configuration',
        'localization_configuration',
        'outgoing_mail_configuration',
        'currency_configuration',
        'authentication_configuration',
        'captcha_configuration',
        'tax_configuration',
      ],
    };
  },
  computed: {
    permissions() {
      return this.$store.getters['app/getPermissions'];
    },
    user() {
      return this.$store.getters['app/getUser'];
    },
  },
  methods: {
    visibility(key) {
      if (this.user.role_id < 2) {
        return true;
      }
      return includes(this.permissions, key);
    },
    groupVisibility(keys) {
      if (this.user.role_id < 2) {
        return true;
      }
      let permit = false;
      forEach(keys, (key) => {
        if (includes(this.permissions, key)) {
          permit = true;
        }
      });
      return permit;
    },
  },
};
</script>
