<template>
  <div class="bg-white" :style="cssVars">
    <loading :status="loading"></loading>
    <div class="action-btn-block py-1">
      <div class="px-5 w-full flex justify-between">
        <button @click="$router.go(-1)" class="btn btn-app py-0">{{ $t('Back') }}</button>
        <template v-if="isChangeAble">
          <div class="flex">
            <input type="text" :placeholder="$t('Printer width')" v-model="printerSize.width" class="form-input" />
            <input type="text" :placeholder="$t('Printer width')" v-model="printerSize.height" class="form-input" />
            <button @click="print" class="btn btn-app">{{ $t('Print') }}</button>
          </div>
        </template>
        <template v-else>
          <div class="flex space-x-2">
            <button @click="printA4" class="btn btn-app">{{ $t('A4') }}</button>
            <button @click="isChangeAble = !isChangeAble" class="btn btn-app">{{ $t('Change size') }}</button>
            <button @click="print" class="btn btn-app">{{ $t('Print') }}</button>
          </div>
        </template>
        <button @click="generatePDF" class="btn btn-app">
          {{ $t('Download PDF File') }}
        </button>
      </div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :filename="`pdf_file_${$route.params.id}`"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-content-width="100%"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <div id="pdf-content" class="p-3"></div>
        </section>
      </vue-html2pdf>
    </div>
    <div class="flex justify-center">
      <div id="content-to-pdf" class="invoice-box p-2">
        <router-view />
        <template v-if="hideAuthor">
          <p class="text-center text-xs only-print coooo">Powered by: info.codehas@gmail.com</p>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf';
export default {
  name: 'print-layout',
  metaInfo() {
    return {
      titleTemplate: this.$store.getters['app/getAppName'],
    };
  },
  components: { VueHtml2pdf },
  data() {
    return {
      isChangeAble: false,
      printerSize: {
        width: null,
        height: null,
      },
      loading: false,
    };
  },
  mounted() {
    this.printerSize.width = this.printer.printer_width;
    this.printerSize.height = this.printer.printer_height;
    setTimeout(() => {
      this.print();
    }, 5000);
  },
  computed: {
    hideAuthor() {
      if (this.$route.name === 'print-label') {
        return false;
      }
      return true;
    },
    printer() {
      return window.app.printer;
    },
    cssVars() {
      return {
        '--print-font-family': this.printer.font_family,
        '--print-font-size': this.printer.font_size + 'px',
        '--print-font-color': this.printer.font_color,
      };
    },
  },
  methods: {
    printA4() {
      this.printerSize.width = '210mm';
      this.printerSize.height = '297mm';
      this.generatePrint();
    },
    print() {
      this.isChangeAble = false;
      this.generatePrint();
    },
    generatePrint(printerSizew, h) {
      const element = document.createElement('style');
      element.innerHTML = `@page { margin: 0; size : ${this.printerSize.width} ${this.printerSize.height} }`;
      document.head.appendChild(element);
      window.print();
    },
    generatePDF() {
      this.loading = true;
      var x = document.getElementById('content-to-pdf').innerHTML;
      document.getElementById('pdf-content').innerHTML = x;
      this.$refs.html2Pdf.generatePdf();
      setTimeout(() => {
        this.loading = false;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.invoice-box {
  font-family: var(--print-font-family);
  width: 60%;
  font-size: var(--print-font-size);
  color: var(--print-font-color);
  margin-bottom: 15px;
}

@media print {
  .invoice-box {
    width: 100%;
    margin-bottom: 1px;
  }

  .action-btn-block {
    display: none;
  }
}
</style>
